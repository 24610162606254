import {
  ForwardRefRenderFunction,
  MouseEventHandler,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { getTotalNights } from './getTotalNights'
import { DesktopDatePicker } from './DesktopDatePicker'
import { MobileDatePicker } from './MobileDatePicker'
import { Footer } from './Footer'
import { DateObject } from 'react-multi-date-picker'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import {
  isDefined,
  isEmpty,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useDatePicker } from './DatePickerContext'
import { useFormContext } from 'react-hook-form'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { NavIcon } from './NavIcon'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { useMedia } from 'src/ui/hooks/useMedia'

const DatePickerWithRef: ForwardRefRenderFunction<{
  set?: (key: string, value: number) => void
}> = ({}, ref) => {
  const languageConfig = useLanguageConfig()
  const { checkIn, checkOut, daysStatusToShow } = useDatePicker()
  const { media } = useMedia()
  const [hasError, setHasError] = useState(false)

  const isRTL = languageConfig.properties.isRTL
  const nights = getTotalNights(checkIn, checkOut)

  const { setValue, clearErrors, formState, getFieldState } =
    useFormContext<DatesFormValues>()

  const error = getFieldState('dates', formState).error

  useEffect(() => {
    setHasError(isDefined(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkIn])

  const renderNav = (
    direction: 'left' | 'right',
    handleMonthChange: MouseEventHandler,
    disabled: boolean,
  ) => {
    return (
      <NavIcon
        icon={getIcon(direction)}
        onClick={handleMonthChange}
        disabled={disabled}
      />
    )
  }

  const getIcon = (direction: 'left' | 'right') => {
    if (!isRTL) {
      return direction
    }

    return direction === 'right' ? 'left' : 'right'
  }

  const handleDatesChange = (selectedDates: DateObject[]) => {
    if (isUndefined(selectedDates) || isEmpty(selectedDates)) {
      return
    }

    clearErrors()

    const checkIn = selectedDates[0]?.toDate()
    const checkOut = selectedDates[1]?.toDate()

    if (isDefined(checkIn) && isClosedDay(checkIn)) {
      setValue('dates', { checkIn: undefined, checkOut: undefined })
      return
    }

    if (Time.isDifferentDay(checkIn, checkOut)) {
      setValue('dates', { checkIn, checkOut })
    } else {
      setValue('dates', { checkIn: undefined, checkOut: undefined })
    }

    function isClosedDay(day: Date) {
      return daysStatusToShow.some(
        status =>
          Time.isSameDay(status.date, day) &&
          status.restriction.status === 'CLOSED',
      )
    }
  }

  const handleCloseNotification = () => {
    clearErrors()
  }

  const footer = (
    <Footer
      key="footer"
      position="bottom"
      nights={nights}
      hasError={hasError}
      errorMessage={error?.message}
      handleCloseNotification={handleCloseNotification}
    />
  )

  const isMobileOrTablet = media === 'mobile' || media === 'tablet'

  return (
    <div className="h-full">
      {isMobileOrTablet ? (
        <MobileDatePicker
          onDatesChange={handleDatesChange}
          renderNav={renderNav}
          footer={footer}
          hasError={hasError}
        />
      ) : (
        <DesktopDatePicker
          ref={ref}
          onDatesChange={handleDatesChange}
          renderNav={renderNav}
          footer={footer}
          hasError={hasError}
        />
      )}
    </div>
  )
}

export const DatePicker = forwardRef(DatePickerWithRef)
