import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { DatesFormValues } from 'src/ui/hooks/useDatesRangeForm'
import { isDayDisabled } from './isDayDisabled'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import {
  areTwoMonthStatusLoaded,
  CalendarDayStatus,
  getFirstMonthNotLoaded,
  createRestrictionsAttributes,
} from 'src/core/Availability/domain/CalendarDayStatus.model'
import { container } from 'src/core/Shared/_di'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { useTrans } from 'src/ui/hooks/useTrans'

export interface DayVariant {
  type: 'enabled' | 'disabled'
  tooltip?: string
}

interface DatePickerContext {
  isLoading: boolean
  checkIn?: Date
  checkOut?: Date
  minDate: Date
  maxDate: Date
  locale: string
  range: Date[]
  daysStatus: CalendarDayStatus[]
  changeVisibleMonths: (startMonth: Date, endMonth: Date) => Promise<void>
  getDayVariant: (day: Date) => DayVariant
}

const defaultDatePickerContext: DatePickerContext = {
  isLoading: false,
  checkIn: new Date(),
  checkOut: undefined,
  minDate: new Date(),
  maxDate: new Date(),
  locale: 'en',
  range: [],
  daysStatus: [],
  changeVisibleMonths: async () => {},
  getDayVariant: () => ({ type: 'enabled' }),
}

export const DatePickerContext = createContext<DatePickerContext>(
  defaultDatePickerContext,
)

interface DatePickerProviderProps {
  hotelId: string
  market: string
  groupCode?: string
  children: ReactNode
}

/***
 * Este provider necesita estar dentro de un FormProvider para que pueda acceder a los valores del formulario
 */
export const DatePickerProvider: FC<DatePickerProviderProps> = ({
  hotelId,
  market,
  groupCode,
  children,
}) => {
  const { trans } = useTrans(['common'])
  const [isLoading, setIsLoading] = useState(true)
  const [visibleMonths, setVisibleMonths] = useState({
    start: new Date(),
    end: new Date(),
  })
  const [daysStatus, setDaysStatus] = useState<CalendarDayStatus[]>([])

  const { watch } = useFormContext<DatesFormValues>()
  const checkIn = watch('dates.checkIn')
  const checkOut = watch('dates.checkOut')

  const languageConfig = useLanguageConfig()
  const locale = languageConfig.properties.culture

  const maxDate = datesManager.getMaxDate()
  const minDate = datesManager.getMinDate()
  const range = [checkIn, checkOut].filter(isDefined)

  const changeCurrentMonth = useCallback(
    async (month: Date) => {
      if (!areTwoMonthStatusLoaded(daysStatus, month)) {
        try {
          setIsLoading(true)
          const firstMonthToLoad = getFirstMonthNotLoaded(daysStatus, month)
          const result = await container.resolve('getTwoMonthDaysStatus')({
            hotelId,
            month: firstMonthToLoad,
            market,
            groupCode,
          })
          if (result.length > 0) {
            setDaysStatus(prev => [...prev, ...result])
          }
        } finally {
          setIsLoading(false)
        }
      }
    },
    [daysStatus, hotelId, market, groupCode],
  )

  const changeVisibleMonths = useCallback(
    async (startMonth: Date, endMonth: Date) => {
      await changeCurrentMonth(startMonth)
      setVisibleMonths({ start: startMonth, end: endMonth })
    },
    [changeCurrentMonth],
  )

  const getDayVariant = (day: Date): DayVariant => {
    if (isDayDisabled({ day, checkIn, checkOut })) {
      return { type: 'disabled' }
    }

    if (isDefined(checkIn) && isUndefined(checkOut)) {
      if (isDayAffectedByMinStay(day)) {
        const status = findStatusFor(checkIn)
        return {
          type: 'disabled',
          tooltip: trans('calendar_restriction-message_min-stay', {
            count: status?.restriction.quantity,
          }),
        }
      }

      if (isDayAffectedByClosed(day)) {
        return {
          type: 'disabled',
          tooltip: trans('calendar_restriction-message_closed'),
        }
      }

      if (isMinStayDayButNotCheckIn(day)) {
        return {
          type: 'enabled',
        }
      }
    }

    if (hasClosedRestriction(day)) {
      if (Time.isSameDay(checkOut, day)) {
        return {
          type: 'enabled',
        }
      }

      return {
        type: 'disabled',
        tooltip: trans('calendar_restriction-message_closed'),
      }
    }

    if (hasMinStayRestriction(day)) {
      if (Time.isSameDay(checkOut, day)) {
        return {
          type: 'enabled',
        }
      }

      const status = findStatusFor(day)
      return {
        type: 'enabled',
        tooltip: trans('calendar_restriction-message_min-stay', {
          count: status?.restriction.quantity,
        }),
      }
    }

    return { type: 'enabled' }

    function findStatusFor(day: Date) {
      return daysStatus.find(status => Time.isSameDay(status.date, day))
    }

    function hasClosedRestriction(day: Date) {
      const dayStatus = findStatusFor(day)
      const isClosedDay = dayStatus?.restriction.status === 'CLOSED'

      if (!isClosedDay) {
        return false
      }

      if (isRangeDefined() || isUndefined(checkIn)) {
        return true
      }

      if (day > checkIn) {
        const dayBefore = Time.fromDate(day).subtract(1, 'day').toDate()
        const dayBeforeStatus = findStatusFor(dayBefore)
        return !(
          dayBefore >= checkIn &&
          dayBeforeStatus?.restriction.status !== 'CLOSED'
        )
      }

      return false
    }

    function isRangeDefined() {
      return isDefined(checkIn) && isDefined(checkOut)
    }

    function isDayAffectedByClosed(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      return daysStatus.some(
        status =>
          status.restriction.status === 'CLOSED' &&
          status.date > checkIn &&
          status.date < day,
      )
    }

    function hasMinStayRestriction(day: Date) {
      return daysStatus.some(
        status =>
          status.restriction.status === 'MIN_STAY' &&
          Time.isSameDay(status.date, day),
      )
    }

    function isMinStayDayButNotCheckIn(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      return daysStatus.some(
        status =>
          status.restriction.status === 'MIN_STAY' &&
          Time.isSameDay(status.date, day) &&
          Time.isDifferentDay(checkIn, day),
      )
    }

    function isDayAffectedByMinStay(day: Date) {
      if (isUndefined(checkIn)) {
        return false
      }

      return daysStatus.some(
        status =>
          status.restriction.status === 'MIN_STAY' &&
          isDefined(status.restriction.quantity) &&
          Time.isSameDay(status.date, checkIn) &&
          checkIn < day &&
          Time.fromDate(checkIn)
            .add(status.restriction.quantity, 'day')
            .toDate() > day,
      )
    }
  }

  const visibleRestrictions = useMemo(
    () =>
      createRestrictionsAttributes(
        daysStatus,
        visibleMonths.start,
        visibleMonths.end,
      ),
    [visibleMonths, daysStatus],
  )

  return (
    <DatePickerContext.Provider
      value={{
        isLoading,
        checkIn,
        checkOut,
        locale,
        getDayVariant,
        minDate,
        maxDate,
        range,
        daysStatus,
        changeVisibleMonths,
      }}
    >
      <div {...visibleRestrictions}>{children}</div>
    </DatePickerContext.Provider>
  )
}

export const useDatePicker = () => useContext(DatePickerContext)
