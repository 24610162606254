import { Time } from 'src/core/Shared/infrastructure/Time'

export interface CalendarDayStatus {
  date: Date
  priceRange: DayPriceRange
  restriction: CalendarDayRestriction
}

export type DayPriceRange = 'EMPTY' | 'LOW' | 'MEDIUM' | 'HIGH'

interface CalendarDayRestriction {
  status: DayRestriction
  // Indicates number of days for MIN_STAY or MAX_STAY restrictions
  quantity?: number
}

export type DayRestriction =
  | 'OPEN'
  | 'CLOSED'
  | 'NO_ARRIVAL'
  | 'NO_DEPARTURE'
  | 'MIN_STAY'
  | 'MAX_STAY'
  | 'LEAD_DAYS_TIME'
  | 'CLOSED_TO_GROUP_CODE'
export interface CalendarStatusCriteria {
  hotelId: string
  month: Date
  market: string
  groupCode?: string
}

export const areTwoMonthStatusLoaded = (
  daysStatus: CalendarDayStatus[],
  initialDate: Date,
) => {
  const firstMonth = Time.fromDate(initialDate)
  const secondMonth = Time.fromDate(initialDate).add(1, 'month')
  return (
    isMonthLoaded(daysStatus, firstMonth) &&
    isMonthLoaded(daysStatus, secondMonth)
  )
}

const isMonthLoaded = (daysStatus: CalendarDayStatus[], month: Time) => {
  return daysStatus.some(({ date }) =>
    month.isSame(Time.fromDate(date), 'month'),
  )
}

export const getFirstMonthNotLoaded = (
  daysStatus: CalendarDayStatus[],
  initialDate: Date,
) => {
  const firstMonth = Time.fromDate(initialDate)
  const secondMonth = Time.fromDate(initialDate)
    .add(1, 'month')
    .startOf('month')

  if (!isMonthLoaded(daysStatus, firstMonth)) {
    return firstMonth.startOf('month')
  }

  return secondMonth
}

const restrictionAttributes: { [restriction in DayRestriction]?: string } = {
  CLOSED: 'data-quantum-restriction-closed',
  NO_ARRIVAL: 'data-quantum-restriction-no-arrival',
  NO_DEPARTURE: 'data-quantum-restriction-no-departure',
  MIN_STAY: 'data-quantum-restriction-min-stay',
  MAX_STAY: 'data-quantum-restriction-max-stay',
  LEAD_DAYS_TIME: 'data-quantum-restriction-lead-days-time',
}

export interface RestrictionsAttributes {
  [restriction: string]: (true | number)[]
}

export const createRestrictionsAttributes = (
  daysStatus: CalendarDayStatus[],
  startDate: Date,
  endDate: Date,
) => {
  const restrictions: RestrictionsAttributes = {}
  for (const dayStatus of daysStatus) {
    if (dayStatus.date < startDate || dayStatus.date > endDate) {
      continue
    }
    const { status, quantity } = dayStatus.restriction
    const attribute = restrictionAttributes[status]
    if (!attribute) {
      continue
    }
    restrictions[attribute] ??= []
    const value = quantity ?? true
    if (!restrictions[attribute].includes(value)) {
      restrictions[attribute].push(value)
    }
  }
  return restrictions
}
